import {ISCC_EU} from "constants/certificationTemplateDetails";
import {DIV_CODE_SAF} from "constants/divisionDetails";
import {getCertificationSystemListByCountryAndDivision} from "modules/DocManager/content/getDetailedViewConfig";
import {useFeatureFlag} from "modules/common/FeatureFlag/FeatureFlagContext";
import FEATURE_FLAGS from "modules/common/FeatureFlag/featureFlagConstants";
import PropTypes from "prop-types";
import {useEffect, useMemo} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import Datepicker from "./Datepicker/index";

const SupplierAndRecipientInfo = ({
  status,
  isDisabled,
  defaultValuesForm,
  selectedCountry,
  divisionCode,
  certificationSystem,
}) => {
  const {recipientReceiptAddress, supplierDispatchAddress} = defaultValuesForm;

  const {
    register,
    formState: {errors},
    control,
    watch,
    setValue,
    getValues,
    trigger,
  } = useFormContext();

  const watchRec = watch("recipientReceiptAddressSameFlag");
  const watchSup = watch("supplierDispatchAddressSameFlag");

  const watchCertificationSystem = watch("certificationSystem");

  useEffect(() => {
    if (watchRec) {
      setValue("recipientReceiptAddress", getValues("recipientAddress"));
    } else {
      setValue("recipientReceiptAddress", recipientReceiptAddress);
    }

    if (watchSup) {
      setValue("supplierDispatchAddress", getValues("supplierAddress"));
    } else {
      setValue("supplierDispatchAddress", supplierDispatchAddress);
    }
    trigger("supplierDispatchAddress");
    trigger("recipientReceiptAddress");
  }, [
    setValue,
    watchRec,
    watchSup,
    getValues,
    trigger,
    recipientReceiptAddress,
    supplierDispatchAddress,
  ]);

  const certificationSystemOptions = useMemo(
    () =>
      getCertificationSystemListByCountryAndDivision(
        selectedCountry,
        divisionCode,
      ),
    [selectedCountry, divisionCode],
  );

  // useEffect to auto-select if only one option is available
  useEffect(() => {
    if (certificationSystemOptions.length === 1) {
      setValue("certificationSystem", certificationSystemOptions[0]);
    }
  }, [certificationSystemOptions, setValue]);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const DOC_MAN_DE_SAF_CHANGES = useFeatureFlag(
    FEATURE_FLAGS.DOC_MAN_DE_SAF_CHANGES,
  );

  const shouldRender = useMemo(() => {
    if (
      DOC_MAN_DE_SAF_CHANGES &&
      divisionCode === DIV_CODE_SAF &&
      watchCertificationSystem === ISCC_EU
    ) {
      return true;
    }
    return false;
  }, [DOC_MAN_DE_SAF_CHANGES, divisionCode, watchCertificationSystem]);

  return (
    <div className="flex flex-col gap-5 w-full">
      <FormGroup>
        <Label for="supplierName" className="fw-normal mb-4">
          Name of supplier
        </Label>
        <Input
          type="text"
          id="supplierName"
          {...computeProps("supplierName", {
            required: "Please enter name of supplier",
          })}
          data-test="supplierName"
          invalid={!!errors.supplierName && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.supplierName && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.supplierName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="supplierAddress" className="fw-normal mb-4">
          Address of supplier
        </Label>
        <Input
          type="text"
          id="supplierAddress"
          {...computeProps("supplierAddress")}
          data-test="supplierAddress"
          invalid={!!errors.supplierAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
        />
        {errors.supplierAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.supplierAddress?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="certificationSystem" className="fw-normal mb-4">
          Certification system
        </Label>
        <Input
          type="select"
          id="certificationSystem"
          data-test="certificationSystem"
          {...computeProps("certificationSystem", {
            required: "Please select a certification system",
          })}
          invalid={!!errors.certificationSystem && !isDisabled}
          disabled={isDisabled}
        >
          <option value="">Please select option</option>
          {certificationSystemOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Input>
        {errors.certificationSystem && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.certificationSystem?.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="certificateNumber" className="fw-normal mb-4">
          Certificate number
        </Label>
        <Input
          type="text"
          id="certificateNumber"
          data-test="certificateNumber"
          {...computeProps("certificateNumber")}
          invalid={!!errors.certificateNumber && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {!isDisabled && errors.certificateNumber && (
          <FormFeedback className="mt-2">
            {errors.certificateNumber.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientName" className="fw-normal mb-4">
          Name of recipient
        </Label>
        <Input
          type="text"
          id="recipientName"
          data-test="recipientName"
          {...computeProps("recipientName")}
          invalid={!!errors.recipientName && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.recipientName && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.recipientName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientAddress" className="fw-normal mb-4">
          Address of recipient
        </Label>
        <Input
          type="text"
          id="recipientAddress"
          data-test="recipientAddress"
          {...computeProps("recipientAddress")}
          invalid={!!errors.recipientAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
        />
        {errors.recipientAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.recipientAddress.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="contractNumber" className="fw-normal mb-4">
          Contract number
        </Label>
        <Input
          type="text"
          id="contractNumber"
          data-test="contractNumber"
          {...computeProps("contractNumber")}
          invalid={!!errors.contractNumber && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.contractNumber && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.contractNumber.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="supplierDispatchAddress" className="fw-normal mb-4">
          Address of dispatch/shipping point of the sustainable material
          {shouldRender
            ? "Address of dispatch/shipping point of the sustainable material"
            : "Address of dispatch"}
        </Label>
        <Input
          type="text"
          id="supplierDispatchAddress"
          data-test="supplierDispatchAddress"
          {...computeProps("supplierDispatchAddress", {
            required: "Please enter address of dispatch",
          })}
          invalid={!!errors.supplierDispatchAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled || watchSup}
        />
        {errors.supplierDispatchAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.supplierDispatchAddress.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("supplierDispatchAddressSameFlag")}
          type="checkbox"
          id="supplierDispatchAddressSameFlag"
          data-test="supplierDispatchAddressSameFlag"
          disabled={isDisabled}
        />

        <Label for="supplierDispatchAddressSameFlag">
          Same as address of supplier
        </Label>
      </FormGroup>
      <FormGroup>
        <Label for="recipientReceiptAddress" className="fw-normal mb-4">
          {shouldRender
            ? "Address of receipt/receiving point of the sustainable material"
            : "Address of receipt"}
        </Label>
        <Input
          type="text"
          id="recipientReceiptAddress"
          data-test="recipientReceiptAddress"
          {...computeProps("recipientReceiptAddress", {
            required:
              "Please enter address of receipt/receiving point of the sustainable material",
          })}
          invalid={!!errors.recipientReceiptAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled || watchRec}
        />
        {errors.recipientReceiptAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.recipientReceiptAddress.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("recipientReceiptAddressSameFlag")}
          type="checkbox"
          id="recipientReceiptAddressSameFlag"
          data-test="recipientReceiptAddressSameFlag"
          disabled={isDisabled}
        />

        <Label for="recipientReceiptAddressSameFlag">
          Same as address of recipient
        </Label>
      </FormGroup>

      <FormGroup>
        <Label for="materialDispatchDatez" className="fw-normal mb-4">
          {shouldRender
            ? "Date of dispatch of the sustainable material"
            : "Date of dispatch"}
        </Label>
        <Datepicker
          id="materialDispatchDatez"
          name="materialDispatchDatez"
          control={control}
          error={errors.materialDispatchDatez}
          rules={{
            required: "Please enter date of dispatch",
          }}
          disabled={isDisabled}
        />
      </FormGroup>

      {divisionCode === DIV_CODE_SAF && !shouldRender && (
        <>
          <div className="flex">
            <FormGroup className="flex flex-col flex-1">
              <Label for="bioProductProducer" className="fw-normal mb-4">
                Producer of biofuel/bioliquid/biomass fuel
              </Label>
              <div className="h-full flex">
                <FormGroup
                  check
                  inline
                  className="form-check-alt form-check-lg !mb-0"
                >
                  <Input
                    type="checkbox"
                    id="bioProductProducer"
                    data-test="bioProductProducer"
                    {...computeProps("bioProductProducer")}
                    invalid={!!errors.bioProductProducer}
                    disabled={isDisabled}
                  />
                  <Label check for="bioProductProducer">
                    Yes
                  </Label>
                </FormGroup>
              </div>
            </FormGroup>
            <FormGroup className="flex flex-col flex-1">
              <Label for="producerInstallationDate" className="fw-normal">
                Date of installation
              </Label>
              <span className="mt-[-21px]">
                <Datepicker
                  id="producerInstallationDate"
                  name="producerInstallationDate"
                  control={control}
                  error={errors.producerInstallationDate}
                  disabled={isDisabled}
                />
              </span>
            </FormGroup>
          </div>
          <div className="flex">
            <FormGroup className="flex flex-col flex-1">
              <Label for="bioProductUser" className="fw-normal mb-4">
                User of bioliquid/biomass fuel
              </Label>
              <div className="h-full flex">
                <FormGroup
                  check
                  inline
                  className="form-check-alt form-check-lg !mb-0"
                >
                  <Input
                    type="checkbox"
                    id="bioProductUser"
                    data-test="bioProductUser"
                    {...computeProps("bioProductUser")}
                    invalid={!!errors.bioProductUser}
                    disabled={isDisabled}
                  />
                  <Label check for="bioProductUser">
                    Yes
                  </Label>
                </FormGroup>
              </div>
            </FormGroup>
            <FormGroup className="flex flex-col flex-1">
              <Label for="userInstallationDate" className="fw-normal">
                Date of installation
              </Label>
              <span className="mt-[-21px]">
                <Datepicker
                  id="userInstallationDate"
                  name="userInstallationDate"
                  control={control}
                  error={errors.userInstallationDate}
                  disabled={isDisabled}
                />
              </span>
            </FormGroup>
          </div>
        </>
      )}
    </div>
  );
};
SupplierAndRecipientInfo.propTypes = {
  status: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultValuesForm: PropTypes.object,
  selectedCountry: PropTypes.string,
  divisionCode: PropTypes.string,
  certificationSystem: PropTypes.string,
};
export default SupplierAndRecipientInfo;
